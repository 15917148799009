import { on } from 'delegated-events';

import showPopup from './modals';
import { isVisible, findSiblings, slideToggle, slideUp, slideDown } from './utils/utils';

export default function () {
    document.querySelectorAll('.user-orders-list .order-header button').forEach(button => {
        button.addEventListener('click', function () {
            findSiblings(this.parentElement.parentElement).forEach(orderInfo => slideUp(orderInfo.getElementsByClassName('order-detailed-info')[0]));
            slideToggle(this.parentElement.parentElement.querySelector('.order-detailed-info'));
        });
    });

    document.querySelectorAll('.single-saved-cart .buttons-wrapper > i').forEach(i => {
        i.addEventListener('click', function () {
            slideToggle(this.parentElement.nextElementSibling);
        });
    });

    // Guziki w drzewie kategorii
    const tree = document.getElementById('tree');

    if (tree) {
        tree.querySelectorAll('li.t0.parent').forEach(li => {
            const button = document.createElement('button');
            button.setAttribute('title', 'Rozwiń kategorię');

            li.appendChild(button);
        });

        tree.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', function () {
                this.parentElement.classList.toggle('downarrow');
            });
        });
    }

    on('click', '.gs-filtr-container button', function (e) {
        e.preventDefault();
        this.parentElement.querySelectorAll('ul')[0].classList.toggle('show-all');
    });

    // DETAIL ACCORDION
    on('click', '.detail-accordion-trigger', function () {
        this.classList.toggle('info-visible');
    });

    // DETAIL LOUPE
    on('click', '.detail-loupe', function () {
        const toClick = document.querySelector('.product-images .swiper-slide-active');

        if (toClick) {
            toClick.click();
        }
    });

    // FAQ
    const faqListSpan = document.querySelectorAll('.faq-list span');

    faqListSpan.forEach(span => {
        span.addEventListener('click', function () {
            span.parentElement.classList.toggle('show');
            span.parentElement.classList.remove('instant');

            const id = span.parentElement.getAttribute('id');

            if (id) {
                if (history.pushState) {
                    history.pushState(null, null, `faq#${id}`);
                } else {
                    location.hash = `faq#${id}`;
                }
            }
        });
    });

    if (window.location.href.indexOf("faq") > -1) {
        let hash = window.location.hash;

        if (hash) {
            hash = hash.substring(1);
            const elem = document.querySelector(`#${hash}`);

            elem.classList.add('show', 'instant');
            elem.parentElement.closest('li').classList.add('show', 'instant');
        }
    }

    // TOGGLER COMPANY
    on('click', '.cart-toggler', function () {
        let toShow;

        if (this.type === 'checkbox') {
            toShow = this.parentElement.nextElementSibling;
            slideToggle(toShow);
        }

        if (this.type === 'radio') {
            toShow = document.getElementsByClassName('toShowCompany')[0];

            if (this.value === '1' && !isVisible(toShow)) {
                slideDown(toShow);
            }

            if (this.value === '0' && isVisible(toShow)) {
                slideUp(toShow);
            }
        }
    });

    // FILTER
    const filter = document.getElementById('filter');
    const sorter = document.querySelector('[data-dropdown-type="sort"]');

    if (filter) {
        function createFilterSorter() {
            const filterSorter = sorter.cloneNode(true);
            sorter.remove();
            filter.querySelector('.close-filter').insertAdjacentElement('afterend', filterSorter);
            filterSorter.querySelector('.dropdown-button').addEventListener('click', function () {
                this.parentElement.classList.toggle('show-dropdown-rwd');
            });
            document.addEventListener('click', function (event) {
                const element = filterSorter;

                if (!element.contains(event.target) && isVisible(element)) {
                    element.classList.remove('show-dropdown-rwd');
                }
            });
        }

        const body = document.body;
        const isCanvasFilter = document.body.hasAttribute('data-canvas-filter');

        if (isCanvasFilter) { // IF CANVAS FILTER
            on('click', '.filter-trigger', function () {
                if (window.innerWidth < 1280) {
                    body.classList.add('overlay-visible');
                    body.classList.add('show-filter');
                    filter.style.display = 'block';
                } else {
                    document.getElementById('container').classList.toggle('show-side-filter');
                }
            });

            document.addEventListener('mouseup', function (e) {
                const isClickInside = filter.contains(e.target);

                if (!isClickInside) {
                    document.getElementById('container').classList.remove('show-side-filter');
                }
            });
        } else { // IF NOT CANVAS FILTER
            on('click', '.filter-trigger', function () {
                if (window.innerWidth < 1280) {
                    body.classList.add('overlay-visible');
                    body.classList.add('show-filter');
                    filter.style.display = 'block';

                    if (!filter.querySelector('.dropdown-picker[data-dropdown-type="sort"]') && sorter) createFilterSorter();
                }
            });
        }

        document.addEventListener('mouseup', function (e) {
            const isClickInside = filter.contains(e.target);

            if (!isClickInside) {
                document.getElementById('container').classList.remove('show-side-filter');
            }
        });

        document.querySelector('.close-filter').addEventListener('click', function () {
            if (window.innerWidth < 1280) {
                body.classList.remove('overlay-visible');
                body.classList.remove('show-filter');
                filter.style.display = 'none';
            }
        });

        filter.querySelectorAll('.gs-filtr-container > strong').forEach(strong => {
            strong.addEventListener('click', function () {
                if (window.innerWidth < 1280) {
                    this.parentElement.classList.toggle('show-list');
                }
            });
        });

        filter.querySelector('.close-filter-bottom > span').addEventListener('click', function () {
            if (window.innerWidth < 1280) {
                body.classList.remove('overlay-visible');
                body.classList.remove('show-filter');
                filter.style.display = 'none';
            }
        });
    }

    // NEWSLETTER POPUP SUBMIT
    on('click', '.goshop-modal .newsletter-clauses-wrapper ~ button[type="submit"]', function (e) {
        const modal = document.querySelector('.basicLightbox');
        const standardNewsletter = modal.classList.contains('standard-newsletter');
        const checkboxes = modal.querySelectorAll('[type="checkbox"]:checked');

        if (checkboxes.length !== 1) {
            modal.querySelector('.newsletter-clauses-wrapper').classList.add('unchecked');
            e.preventDefault();
        } else if (!standardNewsletter && !modal.querySelector('.valid')) {
            const inputField = modal.querySelector('input[type="text"]');

            inputField.dispatchEvent(new Event('focusin', {
                bubbles: true,
                cancelable: true,
                type: 'focusin'
            }));
            inputField.parentElement.classList.add('invalid', 'filled');
        } else {
            window.currentModalInstance.close();

            const recaptchaItem = modal.querySelector('.g-recaptcha-response');
            
            fetch('/api/frontend/newsletter', {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify({
                    email: standardNewsletter ? document.querySelector('#newsletter input[type="text"]').value : modal.querySelector('input[type="text"]').value,
                    recaptcha: recaptchaItem ? recaptchaItem.value : ''
                }),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(response => response.json())
                .then(data => {
                    standardNewsletter ? document.querySelector('#newsletter input[type="text"]').value = '' : modal.querySelector('input[type="text"]').value = '';
                    showPopup({ body: data.message });
                })
                .catch(error => {
                    standardNewsletter ? document.querySelector('#newsletter input[type="text"]').value = '' : modal.querySelector('input[type="text"]').value = '';
                    console.log(error);
                });
        }
    });

    // DETAIL SHIPPING COST POPUP
    on('click', '.shipping-cost-popup-trigger', function () {
        const shippingCostInfo = this.parentElement.querySelector(".shipping-cost-info");

        if (!shippingCostInfo) {
            console.error("no shipping cost info for popup");
            return;
        };

        showPopup({
            header: 'Koszty dostawy',
            body: shippingCostInfo.innerText
        });
    });
}
