import { on } from 'delegated-events';
import Cookies from 'js-cookie';
export default function () {
    on("click", ".dark-mode-toggle", function () {

        if (document.body.classList.contains("dark-mode")) {
            document.body.classList.remove("dark-mode");
            Cookies.remove('dark_mode');
        } else {
            document.body.classList.add("dark-mode");
            Cookies.set('dark_mode', '1', { expires: 365 });
        }
    });
}