import { Pagination, Navigation } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Pagination],
                preloadImages: false,
                //autoplay: {
                //    delay: 1000
                //},
                lazy: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    beforeTransitionStart: function (instance) {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const slideImg = currentSlide.querySelector('img');
                        
                        if (slideImg.hasAttribute('src')) return;

                        currentSlide.classList.add('loaded');
                        slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
                    }
                }
            });

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.main-page-products-wrapper.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGapMainpage').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGapMainpage').replace('px', '');
                slider1.update();
            });

            const slider2 = new Swiper('.sidebar-products-wrapper.slider2 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                lazy: true,
                loop: true,
                spaceBetween: 15,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button2',
                    prevEl: '.swiper-button-prev.slider-button2'
                }
            });

            const slider3 = new Swiper('.main-page-products-wrapper.slider3 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button3',
                    prevEl: '.swiper-button-prev.slider-button3'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider3.on('breakpoint', function () {
                slider3.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider3.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider3.update();
            });

            //PRODUCERS
            const producersBanner = new Swiper('.producers-banner .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.producer-button',
                    prevEl: '.swiper-button-prev.producer-button'
                },
                loop: false,
                spaceBetween: 61,
                breakpoints: {
                    1: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    601: {
                        slidesPerView: 3
                    },
                    1100: {
                        slidesPerView: 4
                    },
                    1280: {
                        slidesPerView: 5
                    },
                    1681: {
                        slidesPerView: 6
                    }
                }
            });
        });
    }, false);
}