import { on } from 'delegated-events';
export default function () {
    const headerContactTrigger = document.querySelector(".header-contact-trigger");
    on("click", ".header-contact-trigger", function () {
        headerContactTrigger.classList.add("open");
        document.addEventListener("click", hideBox);
    });
    function hideBox() {
        if (event.target.classList.contains("header-contact-open")) {
            return;
        };
        headerContactTrigger.classList.remove("open");
        document.removeEventListener("click", hideBox);
    }
}